
import { mask } from 'vue-the-mask'
import { Component, Prop, Watch } from 'nuxt-property-decorator'
import DocFssp from '@pp-frontend/shared-packages/classes/doc-value/fssp/DocPerson'
import DocIp from '@pp-frontend/shared-packages/classes/doc-value/fssp/DocIp'
import SearchFormAbstract from '@pp-frontend/shared-packages/classes/search-form/SearchFormAbstract'
import openPdf from '@pp-frontend/ui-kit/components/common/openPdf.vue'
import { Events } from '@pp-frontend/shared-packages/types/enum/Events'
import vSelect from 'vue-select'
import BaseInput from '~/components/UI/Input/BaseInput.vue'
import InputDate from '~/components/UI/Input/InputDate.vue'
import TooltipIp from '~/components/UI/TooltipIp.vue'

@Component({
  components: {
  TooltipIp,
  openPdf,
  InputDate,
  BaseInput,
  vSelect
  },
  directives: {
  mask
  }
  })
export default class SearchFormFssp extends SearchFormAbstract {
  @Prop() showMeta!: boolean
  @Prop() isIframe!: boolean

  USER_EMAIL_REQUIRED = true
  isError: string = ''
  errorDocVal: string = ''
  allowServices = this.$allowServices()
  labels = [
    {
      labelDesktop: 'Физическое лицо',
      labelMobile: 'Физическое лицо'
    },
    {
      labelDesktop: 'Номер исполнительного производства (ИП)',
      labelMobile: 'Номер ИП'
    }
  ]

  docTypeList = [
    {
      label: this.getCurrentText(this.labels[0]),
      value: new DocFssp()
    },
    {
      label: this.getCurrentText(this.labels[1]),
      value: new DocIp()
    }
  ]

  selectedType: any = this.docTypeList[0]

  getCurrentText (label) {
    return label.labelMobile
  }

  getType (): string {
    return 'fssp'
  }

  keydownHandler (map) {
    return {
      ...map,
      8: (e) => {
        e.preventDefault()
      }
    }
  }

  get placeholderText () {
    switch (this.formData.docType()) {
      case 'fio_birth_date_requisite':
        return 'Фамилия Имя Отчество'
      default:
        return 'Номер ИП'
    }
  }

  get formData () {
    return this.selectedType.value
  }

  mounted () {
    this.$lastActivity.setEventListener(Events.SEARCH_FORM_SUBMIT)
    this.$eventBus.$on(Events.DATA_ERROR_FROM_VALIDATION, (error) => {
      const getDocType = () => {
        return error.payerData[0].document_type
      }
      this.errorDocVal = error.error.response.data.error.document_value[0]
      this.isError = getDocType()
      this.formData.docType = getDocType
    })
    this.allowServicesHandler()
  }

  @Watch('allowServices', { deep: true })
  allowServicesHandler () {
    if (!this.allowServices.ctc_by_grz) {
      this.selectedType = this.docTypeList[1]
      this.docTypeList.shift()
    }
    return this.allowServices
  }

  afterSubmitBehavior () {
    if (this.isIframe) {
      const params: any = Object.assign({
        document_type: this.formData.docType(),
        lead_source_id: this.$route.query.lead_source_id
      }, this.formData)
      const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&')
      const url = `https://gos-oplata.ru/search/${this.getType()}?${queryString}`

      if (this.$route.query.target === 'parent') {
        // @ts-ignore
        window.parent.location = url
      } else {
        const win: any = window.open(url, '_blank')
        win.focus()
      }
    } else {
      this.$router.push({
        path: '/search/' + this.getType(),
        query: (Object.assign({
          document_type: this.formData.docType(),
          lead_source_id: window.$nuxt.$route.query.lead_source_id
        }, this.formData) as any)
      })
    }

    return Promise.resolve()
  }
}
